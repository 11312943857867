import React, { useState } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"

import {
  FormContainer,
  FormInputContainer,
  FormInputLabel,
  FormTextInput,
  FormButton,
  FormHeading,
  FormErrorMessage,
  FormText,
} from "../../forms/forms.styled"

const axios = require("axios")
const API = process.env.GATSBY_API_URL || null

const ResetAccount = ({ token, createNotification }) => {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = e => {
    e.preventDefault()

    setErrorMessage(null)

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match")
    }

    const payload = {
      password,
      resetToken: token,
    }

    axios
      .patch(`${API}/resetPassword`, payload)
      .then(response => {
        if (response.data.success) {
          localStorage.setItem("token", response.data.data.token)
          localStorage.setItem("userType", response.data.data.userType)

          createNotification("Password reset. You are now logged in.")

          navigate("/")
        } else {
          setErrorMessage(response.data.message)
        }
      })
      .catch(error => {
        console.info({ error })
      })
  }

  return (
    <FormContainer>
      <FormHeading>Reset Password</FormHeading>

      <FormText>Once reset, please login with your new password</FormText>

      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}

      <form onSubmit={handleSubmit}>
        <FormInputContainer>
          <FormInputLabel>Password</FormInputLabel>
          <FormTextInput
            name="password"
            minLength="8"
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            required
          />
        </FormInputContainer>

        <FormInputContainer>
          <FormInputLabel>Confirm Password</FormInputLabel>
          <FormTextInput
            name="confirm_password"
            minLength="8"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            type="password"
            required
          />
        </FormInputContainer>

        <FormInputContainer>
          <FormButton type="submit">Reset Password</FormButton>
        </FormInputContainer>
      </form>
    </FormContainer>
  )
}

const mapDispatchTosuccess = dispatch => {
  return {
    createNotification: text => {
      const payload = { text }
      dispatch({ type: "NEW_NOTIFICATION", payload })
    },
  }
}

export default connect(null, mapDispatchTosuccess)(ResetAccount)
