import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import queryString from "query-string"

import ResetAccount from "../../components/account/reset/reset"

const Reset = ({ location }) => {
  const token = queryString.parse(location.search).token || null

  return (
    <Layout location={location} title={""}>
      <SEO title="Reset Password" lang="ar" />

      <ResetAccount token={token} />
    </Layout>
  )
}

export default Reset
